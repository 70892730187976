@font-face {
    font-family: 'Satoshi';
    src: local(''),     
    url('Satoshi-Light.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Satoshi-Light.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'Satoshi';
    src: local(''),
    url('Satoshi-LightItalic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Satoshi-LightItalic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}
@font-face {
    font-family: 'Satoshi';
    src: local(''),
    url('Satoshi-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Satoshi-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'Satoshi';
    src: local(''),
    url('Satoshi-Italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Satoshi-Italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}
@font-face {
    font-family: 'Satoshi';
    src: local(''),
    url('Satoshi-Medium.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Satoshi-Medium.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'Satoshi';
    src: local(''),
    url('Satoshi-MediumItalic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Satoshi-MediumItalic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}
@font-face {
    font-family: 'Satoshi';
    src: local(''),
    url('Satoshi-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Satoshi-Bold.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('Satoshi-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Satoshi-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'Satoshi';
    src: local(''),
    url('Satoshi-BoldItalic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Satoshi-BoldItalic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}