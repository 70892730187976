@import url('../fonts/Satoshi.css');

html,
body {
	height: 100%;
}

body {
	color: #28234a;
	font-family: 'Satoshi';
	font-size: 17px;
	line-height: 24px;
	margin: 0;
	padding: 0;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Satoshi';
	font-optical-sizing: auto;
}

p {
	padding: 0;
	margin: 0 0 20px 0;
}

.wrap {
	box-sizing: border-box;
	margin: 0 auto;
	max-width: 1240px;
	width: 100%;
	padding: 0 50px;
	position: relative;

	@media (max-width: 768px) {
		padding: 0 20px;
	}
}

.site-wrap {
	min-height: 100%;
	margin-bottom: -64px;
}

.footer,
.push { height: 64px; }

header {
	box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
	padding: 10px 0;

	.wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		column-gap: 60px;
		min-height: 68px;
		max-width: 100%;

		.logo {
			flex-shrink: 0;
			max-width: 170px;

			svg { width: 100%; }
		}

		.login-info {
			display: flex;
			align-items: center;
			gap: 20px;

			.user {
				font-size: 13px;
			}

			.logout-link {
				background: none;
				border: 2px solid #333;
				border-radius: 6px;
				cursor: pointer;
				font-size: 13px;
				font-weight: 700;
				padding: 5px 15px;
				width: max-content;
				transition: all 0.2s ease-in-out 0s;

				&:hover {
					background: #333;
					color: #fff;
				}
			}
		}
	}
}

.content {
	padding: 40px 0;

	h1 {
		font-size: 32px;
		line-height: 40px;
		padding: 0;
		margin: 0 0 30px 0;
		text-align: center;
	}

	.error {
		border: 2px solid #990f0f;
		border-radius: 20px;
		color: #990f0f;
		margin: 0 0 30px 0;
		margin: 0 auto 30px auto;
		padding: 20px;
		width: max-content;
	}

	.grid-container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 20px;

		.store-card {
			box-shadow: 0 1px 5px rgba(0,0,0,0.15);
			border-radius: 20px;
			padding: 30px;
			position: relative;
			width: 304px;
			transition: box-shadow 0.2s ease-in-out 0s;

			.flex-columns {
				display: flex;
				flex-direction: column;
				align-items: center;
				z-index: 1;

				&.popup {
					align-items: flex-start;
					background: #fff;
					border-radius: 20px;
					box-shadow: 0 5px 20px rgba(0,0,0,0.3);
					position: absolute;
					left: 50%;
					top: calc(50% + 20px);
					transform: translate(-50%, -50%);
					transition: opacity 0.2s ease-in-out 0s, top 0.2s ease-in-out 0s;
					z-index: 2;

					height: 0;
					opacity: 0;
					overflow: hidden;

					.description {
						color: #787780;
						margin: 0 0 10px 0;
					}

					&.active {
						padding: 30px;
						min-width: 100%;
						min-height: 100%;
						top: 50%;

						height: auto;
						opacity: 1;
						overflow: visible;

						@media (max-width: 768px) {
							min-width: calc(100% - 20px);
							min-height: calc(100% - 20px);
						}
					}
				}
			}

			.store-logo {
				flex-shrink: 0;
				margin-bottom: 20px;
				max-height: 40px;
				max-width: 100%;
				width: auto;
			}

			.button {
				border-radius: 20px;
				color: #333;
				cursor: pointer;
				font-size: 15px;
				padding: 8px 30px;
				position: relative;

				&:before {
					border: 2px solid #333;
					border-radius: 20px;
					content: "";
					display: block;
					position: absolute;
					inset: 0;
				}
			}

			.store-name {
				border-bottom: 1px solid #eee;
				cursor: pointer;
				margin: 0 0 10px 0;
				padding: 0 0 10px 0;
				text-align: center;
				width: 100%;

				display: flex;
				align-items: center;
				align-items: center;
				justify-content: space-between;

				svg {
					height: 20px;
				}
			}

			.list-item {
				display: flex;
				align-items: center;
				align-items: center;
				justify-content: space-between;

				color: rgba(145, 153, 253, 100%);
				cursor: pointer;
				width: 100%;

				svg {
					display: none;
					height: 16px;
				}

				&:hover {
					color: rgba(242, 0, 84, 100%);

					svg { display: block; }
				}
			}

			&:hover {
				box-shadow: 0 5px 20px rgba(0,0,0,0.3);

				.button {
					background: linear-gradient(92.37deg, #F20054 -6.13%, #9199FD 75.98%);
					color: #fff;

					&:before { display: none; }
				}
			}
		}
	}
}

.footer {
	background: linear-gradient(90deg, #EFECFF 0%, #C4B8FF 100%);

	.powered {
		font-size: 15px;
		padding: 20px 0;
		text-align: center;

		a {
			color: #28234a;

			&:hover { text-decoration: none; }
		}
	}
}